import styled from 'styled-components';
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = styled.button`
  cursor: pointer;
  color: var(--White, #fff);
  font-family: Satoshi;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  /* H1 - Bold */
  display: flex;
  padding: 0.5rem 2.2rem;
  align-items: center;
  gap: 0.25rem;
  border-radius: 3.125rem;
  background: var(--Blue-700, #3d98f2);
  box-shadow:
    0px 0px 0px 1px #3490ec,
    0px 1px 1px 0px rgba(13, 97, 181, 0.8),
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
  border: none;

  @media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
    cursor: pointer;
    color: var(--White, #FFF);

    /* H4 - 18- medium */
    font-family: Satoshi;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 155.556% */
    /* H1 - Bold */
    display: flex;
    padding: 0.5rem 2rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 3.125rem;
    background: var(--Blue-700, #3d98f2);
    box-shadow:
      0px 0px 0px 1px #3490ec,
      0px 1px 1px 0px rgba(13, 97, 181, 0.8),
      0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    border: none;
    
  }

`;

export default StyledWrapper;
