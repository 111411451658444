import decreased from '../../assets/svg/decrement.svg';
import increased from '../../assets/svg/increment.svg';
import nochange from '../../assets/svg/nochange.svg';
import showFaqAns from '../../assets/svg/showFaqAns.svg';
import closeFaqAns from '../../assets/svg/closeFaqAns.svg';

const RenderIcon = ({ status }) => {
  if (status === 'decreased') {
    return <img src={decreased} alt={`${status}-icon`}></img>;
  }
  if (status === 'increased') {
    return <img src={increased} alt={`${status}-icon`}></img>;
  }
  if (status === 'nochange') {
    return <img src={nochange} alt={`${status}-icon`}></img>;
  }
  if (status === 'showFaqAns') {
    return <img src={showFaqAns} alt={`${status}-icon`}></img>;
  }
  if (status === 'closeFaqAns') {
    return <img src={closeFaqAns} alt={`${status}-icon`}></img>;
  }
};

export default RenderIcon;
