import React, { useEffect } from 'react';
import StyledWrapper from './StyledWrapper';
import RenderIcon from '../RenderIcon';
import { leaderboardData as data, blurLeaderBoard,dummyData} from '../../assets/constants/LeaderBordData';

const LeaderBoard = () => {

  return (
    <StyledWrapper id="leaderboard" blurLeaderBoard={blurLeaderBoard}>
      <p className="lb-heading">Leaderboard</p>
      <div className="leaderboard-container">
        <div className="header-row">
          <div className="affiliate-email">Affiliate email</div>
          <div className="conversions">Conversions</div>
          <div className="place">Place</div>
          <div className="status">{'   '}</div>
        </div>
        <div className="results">
          {(blurLeaderBoard ? dummyData : data).map(({ email, conversions, place, status }, ind) => (
            <div className="data-row" key={ind}>
              <div className="affiliate-email">
                {ind + 1}. {email.split('@')[0].slice(0, 6) + '****'}
              </div>
              <div className="conversions">{conversions}</div>
              <div className="place">{place}</div>
              <div className="status">
                <RenderIcon status={status} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default LeaderBoard;
