import React from 'react';
import Styled from 'styled-components';

const StyledWrapper = Styled.div`   
    display: flex;   
    padding: 1rem 2.5rem;  
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--Blue-300, #A5D2FE);
    background: var(--White, #FFF);   
    position:fixed;
    top:0;    
    width:100%; 
    z-index:10;
    .img-container{
      display:flex;
      align-items:Center;      
    }
    .nav-menu-links{
      display: flex;
      align-items: flex-start;
      gap: 3rem;
      a{
        color: var(--Grey-900, #19191A);
        font-family: Satoshi;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; 
        text-decoration:none;
        cursor:pointer;
      }
    }  
    .cursor-pointer{
      cursor:pointer;
    }
    

`;

export default StyledWrapper;
