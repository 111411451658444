import React from "react";
import podcastIcon from "../../assets/svg/podCastCody.svg";
import StyledWrapper from "./StyledWrapper";

const InvitationCard = () => {
  return (
    <StyledWrapper
      onClick={() => {
        window.open(
          "https://podcasts.apple.com/gb/podcast/the-etsy-seller-podcast/id1661426024",
          "_blank"
        );
      }}
    >
      <div className="flex-column gap-06">
        <span className="ic-prize-1">1st place bonus</span>
        <p>
          Invitation to join Etsy seller podcast with Everbee founder & ceo cody
        </p>
      </div>
      <div className="ic-image-container">
        <img src={podcastIcon} alt="screenshot" />
      </div>
    </StyledWrapper>
  );
};

export default InvitationCard;
