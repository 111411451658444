import React from 'react';
import StyledWrapper from './StyledWrapper';
import titleLogo from '../../assets/svg/title-log-bee.svg';
import Button from '../Button';

const TitleChallenge = () => {
  return (
    <StyledWrapper>
      <div className="title-logo-container">
        <div className="title">
          <span className="bluecolor">Partner</span>
          <span>Challenge</span>
          <span>February</span>
        </div>
        <div className="title-logo">
          <img src={titleLogo} alt="title-logo" />
        </div>
      </div>
      <div className="tag-button">
        <p>Promote EverBee and have a chance to win one of 10 amazing prizes</p>
         <Button text="Join Now"></Button>
      </div>
    </StyledWrapper>
  );
};

export default TitleChallenge;
