import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/NavBar";
import MobileNavbar from "./components/Mobile/Navbar/MobileNavbar";
import { useEffect, useState } from "react";
import { MOBILE_TAB_SCREEN_WIDTH } from "./assets/constants/ScreenSIze";
import HomePage from "./Pages/HomePage";
import TermsAndConditionsPage from "./Pages/TermsAndConditionsPage";
import { Routes, Route } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  function checkIsMobile() {
    return window.matchMedia(`(max-width: ${MOBILE_TAB_SCREEN_WIDTH})`).matches;
  }

  window.onscroll = function () {
    var scrollPos =
      document.documentElement.scrollTop || document.body.scrollTop;

    // Check if at the beginning of the page
    if (scrollPos === 0) {
      // Perform your desired operation here
      setSearchParams({});
    }
  };


  useEffect(() => {
    function handleResize() {
      setIsMobile(checkIsMobile());
    }

    // Initial check
    setIsMobile(checkIsMobile());

    // Add event listener for resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App">
      {isMobile ? <MobileNavbar /> : <Navbar />}
      <Routes>
        <Route path={"/"} element={<HomePage isMobile={isMobile} />}></Route>
        <Route
          path={"/terms-and-conditions"}
          element={<TermsAndConditionsPage />}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
