import RenderIcon from "../RenderIcon";
import React, { useState } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  border-top: ${({ totalQuestions, ind }) => {
    return ind !== 0 ? `1px solid #E6E6E6` : null;
  }};
  display: flex;
  flex-direction:column;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--grey-100, #fafafa);
  /* border: 1px solid red; */

  .question-icon {
    /* border: 1px solid green; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 0.2rem;
    gap: 0.5rem;
  }
  .question {
    color: var(--Grey-900, #19191a);
    font-family: Satoshi;
    font-size: 1.12rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ans {   
    /* border:1px solid red; */
    width:85%;
    align-self: stretch;
    color: var(--Grey-900, #19191a);
    font-family: Satoshi;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 139%;
  }
  .readmore {
    color: var(--Blue-800, #2e8ae5);
    font-family: Satoshi;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 139%;
    text-decoration-line: underline;
  }
  .pointer {
    cursor: pointer;  
  }
  .pointer img{
    cursor: pointer;  
    width: 2.5rem;
    height: 2.5rem;
  }
  .margin-tb-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`;

const MobileFaqCard = ({ question, ans, showAns = false, totalQuestions, ind }) => {
  const [showAnswer, setShowAnswer] = useState(showAns);
  return (
    <StyledWrapper ind={ind}>
      <div className="question-icon">
        <h1 className="question margin-tb-0">{question}</h1>
        <div className="pointer" onClick={() => setShowAnswer(!showAnswer)}>
          <RenderIcon status={showAnswer ? "showFaqAns" : "closeFaqAns"} />
        </div>     
      </div>    
      {showAnswer ? <p className="ans margin-tb-0" dangerouslySetInnerHTML={{ __html: ans }} /> : null}
   
    </StyledWrapper>
  );
};

export default MobileFaqCard;
