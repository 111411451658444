import React from 'react';
import Styled from 'styled-components';
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = Styled.div`  
    /* border:1px dashed red;   */
    display: flex;
    padding-top: 0.5rem;    
    width:80%;
    margin:2rem auto 5rem auto;
    flex-direction: column;
    align-items: center;   
    align-self: stretch;    
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(0, 0, 0, 0.00) 100%);
    .faq-heading{
        text-align: center;
        font-family: Satoshi;
        font-size: 3.5rem;
        font-style: normal;      
        font-weight: 900;
        line-height: 120%; 
        padding:0rem 0.5rem;
        letter-spacing: -0.1825rem;
        text-transform: uppercase;
        background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin:0rem auto 0.5rem auto;
    }
    
    .faq-questions-container{   
        /* border:1px solid blue;        */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:0rem 0.5rem 0rem 0.5rem;
        gap: 0rem;
        align-self: stretch;    
    }

    @media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
    /* border:1px dashed red;   */
    display: flex;
    padding-top: 0.5rem;    
    width:85%;
    margin:auto;
    margin:2rem auto 5rem auto;
    flex-direction: column;
    align-items: center;   
    align-self: stretch;    
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(0, 0, 0, 0.00) 100%);
    .faq-heading{
        text-align: center;
        font-family: Satoshi;
        font-size: 2.5rem;
        font-style: normal;      
        font-weight: 900;
        line-height: 2.25rem; 
        padding:0rem 0.5rem;
        letter-spacing: -0.1825rem;
        text-transform: uppercase;
        background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin:0rem auto 0.5rem auto;
    }
    
    .faq-questions-container{   
        /* border:1px solid blue;        */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:0rem 0.5rem 0rem 0.5rem;
        gap: 0rem;
        align-self: stretch;    
    }
  }
`;

export default StyledWrapper;
