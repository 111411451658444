import RenderIcon from '../RenderIcon';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = styled.div`
  border-top: ${({ totalQuestions, ind }) => {
    return ind !== 0 ? `1px solid #E6E6E6` : null;
  }};
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--grey-100, #fafafa);
  /* border: 1px solid red; */

  .question-ans {
    /* border: 1px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1.25rem 0.2rem;
    gap: 0.5rem;
  }
  .question {
    color: var(--Grey-900, #19191a);
    font-family: Satoshi;
    font-size: 1.85rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ans {
    width: 80%;
    /* border:1px solid red; */
    color: var(--Grey-900, #19191a);
    font-family: Satoshi;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  .readmore {
    color: var(--Blue-800, #2e8ae5);
    font-family: Satoshi;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 139%;
    text-decoration-line: underline;
  }
  .pointer {
    cursor: pointer;
  }
  .margin-tb-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  @media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
    border-top: ${({ totalQuestions, ind }) => {
    return ind !== 0 ? `1px solid #E6E6E6` : null;
  }};
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--grey-100, #fafafa);
  /* border: 1px solid red; */

  .question-ans {
    /* border: 1px solid green; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1.25rem 0.2rem;
    gap: 0.25rem;
  }
  .question {
    color: var(--Grey-900, #19191a);
    font-family: Satoshi;
    font-size: 1.50rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ans {
    width: 80%;
    /* border:1px solid red; */
    color: var(--Grey-900, #19191a);
    font-family: Satoshi;
    font-size: 1.50rem;
    font-style: normal;
    font-weight: 400;    
    line-height: 139%;
  }
  .readmore {
    color: var(--Blue-800, #2e8ae5);
    font-family: Satoshi;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 139%;
    text-decoration-line: underline;
  }
  .pointer {
    cursor: pointer;
  }
  .margin-tb-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  }
`;

const FaqCard = ({ question, ans, showAns = false, totalQuestions, ind }) => {
  const [showAnswer, setShowAnswer] = useState(showAns);
  return (
    <StyledWrapper ind={ind}>
      <div className="question-ans">
        <h1 className="question margin-tb-0">{question}</h1>
        {showAnswer ? <p className="ans margin-tb-0" dangerouslySetInnerHTML={{ __html: ans }} /> : null}
        {/* <span className="readmore">{" "}Read more here</span> */}
      </div>
      <div className="pointer" onClick={() => setShowAnswer(!showAnswer)}>
        <RenderIcon status={showAnswer ? 'showFaqAns' : 'closeFaqAns'} />
      </div>
    </StyledWrapper>
  );
};

export default FaqCard;
