export const leaderboardData = [
  {
    email: "caasiy@everbee.io",
    conversions: 400,
    place: "1st",
    status: "nochange",
  },
  {
    email: "cody@everbee.io",
    conversions: 380,
    place: "2nd",
    status: "increased",
  },
  {
    email: "priyam@everbee.io",
    conversions: 360,
    place: "3rd",
    status: "increased",
  },
  {
    email: "abhi@everbee.io",
    conversions: 350,
    place: "4th",
    status: "decreased",
  },
  {
    email: "pawan@everbee.io",
    conversions: 340,
    place: "5th",
    status: "increased",
  },
  {
    email: "emma@everbee.io",
    conversions: 330,
    place: "6th",
    status: "decreased",
  },
  {
    email: "david@everbee.io",
    conversions: 320,
    place: "7th",
    status: "increased",
  },
  {
    email: "grace@everbee.io",
    conversions: 310,
    place: "8th",
    status: "nochange",
  },
  {
    email: "frank@everbee.io",
    conversions: 308,
    place: "9th",
    status: "increased",
  },
  {
    email: "sarah@everbee.io",
    conversions: 307,
    place: "10th",
    status: "decreased",
  },
];
export const dummyData = [
  {
    email: "resultsHidden@gmail.com",
    conversions: "000",
    place: "1st",
    status: "nochange",
  },
  {
    email: "resultsHidden@gmail.com",
    conversions: "000",
    place: "2nd",
    status: "increased",
  },
  {
    email: "resultsHidden@gmail.com",
    conversions: "000",
    place: "3rd",
    status: "increased",
  },
  {
    email: "resultsHidden@gmail.com",
    conversions: "000",
    place: "4th",
    status: "decreased",
  },
  {
    email: "resultsHidden@gmail.com",
    conversions: "000",
    place: "5th",
    status: "increased",
  },
  {
    email: "resultsHidden@gmail.com",
    conversions: "000",
    place: "6th",
    status: "decreased",
  },
  {
    email: "resultsHidden@gmail",
    conversions: "000",
    place: "7th",
    status: "increased",
  },
  {
    email: "resultsHidden@gmail.com",
    conversions: "000",
    place: "8th",
    status: "nochange",
  },
  {
    email: "resultsHidden@gmail.com",
    conversions: "000",
    place: "9th",
    status: "increased",
  },
  {
    email: "resultsHidden@gmail.com",
    conversions: "000",
    place: "10th",
    status: "decreased",
  },
];
export const blurLeaderBoard = true;
