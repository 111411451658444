import React,{useEffect} from 'react';
import Slider from './Slider';
import styled, { css } from 'styled-components';
import Marquee from 'react-fast-marquee';
import { openAffiliateLinkGoogleForm } from '../../assets/constants/links';

const Button = styled.button`
  cursor: pointer;
  display: flex;
  width: 30.375rem;
  padding: 1.5rem 2.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 3.125rem;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: var(--White, #fff);
  font-family: Satoshi;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  background: var(--Blue-700, #3d98f2);
  box-shadow:
    0px 0px 0px 1px #3490ec,
    0px 1px 1px 0px rgba(13, 97, 181, 0.8),
    0px 1px 0px 0px rgba(255, 255, 255, 0.3) inset;
    ${(props) =>
    props.isMobile &&
    css`
      width: 8.6rem; 
      padding: 0.5rem 1rem;
      font-size: 1.125rem;      
      top:50%;
      
    `}
`;

const PodCastSection = ({isMobile}) => {
  useEffect(()=>{

  },[isMobile])
  return (
    <div
      style={{
        position: 'relative',
        marginTop: '2rem',
        minHeight: isMobile ? 'fit-content' : 'fit-content',
      }}
    >
      <Marquee
        pauseOnHover={true}
        autoFill={true}
        gradient={true}
        gradientWidth={isMobile?300:700}
      >
        <Slider />
      </Marquee>
      <Button isMobile={isMobile} onClick={openAffiliateLinkGoogleForm}>Join Now</Button>
    </div>
  );
};

export default PodCastSection;
