import React from 'react';
import StyledWrapper from './StylesWrapper';
import ProcessStep from './ProcessStep';
import Button from '../Button';

const HowItWorks = () => {
  const processSteps = [
    {
      heading: 'Join our affiliate program and register for competition',
      tag: 'Once you’ve registered you will get an email with all the details about the challenges, as well as tips and tricks, and promotional materials',
    },
    {
      heading:
        'Promote EverBee using provided promo materials and compete with others',
      tag: 'You will have until <span>February 29th</span> to create content about EverBee and publish it online, and start getting conversions',
    },
    {
      heading: 'Get the most new sales and win one of our 10 prizes',
      tag: 'Only new conversions (not renewals) will be counted towards the prize, check the leaderboard to see your score',
    },
  ];
  return (
    <StyledWrapper id="howItWorks">
      <p className="hiw-title">How it works</p>
      <div className="hiw-process-steps">
        {processSteps.map((stepData, ind) => {
          return (
            <ProcessStep
              key={stepData.heading}
              stepNumber={ind + 1}
              heading={stepData.heading}
              tag={stepData.tag}
            />
          );
        })}
      </div>
      <div className="join-button">
        <Button text="Join Now" />
      </div>
    </StyledWrapper>
  );
};

export default HowItWorks;
