export const affiliateJoinLink = 'https://forms.gle/gJWwnfsSZJiKQpgs8';

export const openAffiliateLinkGoogleForm = () => {
  window.open(affiliateJoinLink, '_blank');
};

export const termsAndConditionsLink =
  'https://docs.google.com/document/d/1Cz9rApROvNTZdu7dF_iXc6KIE-B1JSkNN4rAk8R1UZE/edit?usp=sharing';

export const affiliateLink=()=>{
  window.open("https://everbee.io/affiliates/", '_blank');
}  

export const contactUsLink=()=>{
  window.open("https://help.everbee.io/ ", '_blank');
}  

export const openTermsAndConditionsLink = (navigate) => {
  // window.open(termsAndConditionsLink, '_blank');
  navigate("/terms-and-conditions");
};
