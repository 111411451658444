import Styled from "styled-components";
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = Styled.div`
    display: flex;
    padding: 0.5rem 2.5rem;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    .copy-right{
        color: var(--Grey-900, #19191A);
        font-family: Satoshi;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 139%;
    }
    .tc{
        color: var(--Grey-900, #19191A);
        text-align: center;
        font-family: Satoshi;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 139%;
        cursor:pointer;
    }

    @media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
        display: flex;
        padding: 0.5rem 1rem 2rem 1rem;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;
        background: var(--White, #FFF);
        .copy-right{
                /* border:1px solid red; */
                align-self: stretch;
                color: var(--Grey-900, #19191A);
                text-align: center;
                font-family: Satoshi;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 139%; /* 1.39rem */
                margin-bottom:0;
        }
        .tc{
            margin-top:0rem;
            /* border:1px solid red; */
            color: var(--Grey-900, #19191A);
            text-align: center;
            font-family: Satoshi;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 139%; /* 1.39rem */
            cursor:pointer;
        }
  }
`;

export default StyledWrapper;
