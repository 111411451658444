import React from 'react';
import Styled from 'styled-components';
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = Styled.div`
/* border: 5px dashed yellow; */
padding-bottom:15px;
display:flex;
align-items:center;
flex-direction:column;
overflow: hidden;
white-space: nowrap;
wrap:no-wrap;
font-family: Satoshi;
font-size: 7rem;
font-style: normal;
font-weight: 900;
line-height: 6.875rem; /* 91.667% */
letter-spacing: -0.15rem;
background-clip: text;
-webkit-text-fill-color: transparent;
-webkit-text-stroke: 0.03rem #E6E6E6;
.pcs-row-1,
.pcs-row-2,
.pcs-row-3,
.pcs-row-4 {
  background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0rem;
}
.LeftToRight {
  animation: LeftToRight 10s infinite linear;
}

@keyframes  LeftToRight {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}

@media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
   
/* border: 5px dashed yellow; */
padding-bottom:2rem;
display:flex;
align-items:center;
flex-direction:column;
overflow: hidden;
white-space: nowrap;
wrap:no-wrap;
font-family: Satoshi;
font-size: 3.5rem;
font-style: normal;
font-weight: 900;
line-height: 4rem;
letter-spacing: -0.0625rem;
background-clip: text;
-webkit-text-fill-color: transparent;
-webkit-text-stroke: 0.03rem #E6E6E6;
.pcs-row-1,
.pcs-row-2,
.pcs-row-3,
.pcs-row-4 {
  background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0rem;
}
.LeftToRight {
  animation: LeftToRight 10s infinite linear;
}

@keyframes  LeftToRight {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}






}




`;

export default StyledWrapper;

// height:28rem;
// min-height:28rem;
// margin:auto;
