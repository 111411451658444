export const MenuItemDetails = [
  {
    text: 'How it works',
    target: 'howItWorks',
    url: '#',
  },
  {
    text: 'Awards & prizes',
    target: 'awardsAndPrizes',
    url: '#',
  },
  {
    text: 'Leaderboard',
    target: 'leaderboard',
    url: '#',
  },
  {
    text: 'FAQ',
    target: 'faq',
    url: '#',
  },
  {
    text: 'Affiliates',
    target: 'affiliates',
    url: '#',
  },
  {
    text: 'Contact Us',
    target: 'contactUs',
    url: '#',
  },
];
