import React, { useState, useEffect } from "react";
import everbeeLogo from "../../../assets/svg/everbee-logo.svg";
import MobileMenuIcon from "../../../assets/svg/MobileMenuIcon.svg";
import bigcross from "../../../assets/svg/big-cross.svg";
import { MenuItemDetails } from "../../../assets/constants/MenuItemDetails";
import { Link, Events, animateScroll as scroll, scrollSpy } from "react-scroll";
// import useMediaQuery from '@mui/material/useMediaQuery';
import StyledWrapper from "./StyledWrapper";
import {
  openAffiliateLinkGoogleForm,
  affiliateLink,
  contactUsLink,
} from "../../../assets/constants/links";
import { useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function MobileNavbar() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const handleSetActive = (to) => {
    // setOpen(false);
  };
  const navigate = useNavigate();

  const handleScrollTo = (targetId) => {
    if (targetId === "affiliates") {
      affiliateLink();
      setSearchParams({});
    } else if (targetId === "contactUs") {
      contactUsLink();
      setSearchParams({});
    } else if (location.pathname !== "/") {
      navigate(`/?view=${targetId}`);
    } else {
      setSearchParams({ view: targetId });
    }    
  };

  useEffect(() => {
    // Set or remove the overflow: hidden style on the body based on the open prop
    document.body.style.overflow = open ? "hidden" : "auto";
    // Clean up the effect
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);



  useEffect(() => {
    if (searchParams.get("view")) {
      window.scroll(0, document.getElementById(searchParams.get("view")).offsetTop-60)
      // setTimeout(() => {
      //   document
      //     .getElementById(searchParams.get("view"))
      //     ?.scrollIntoView({ behavior: "smooth", block: "start" });
      // }, 100);
    }
  }, [searchParams]);


  return (
    <StyledWrapper open={open}>
      <div className="nav-row-1">
        <div
          className="logo-container cursor-pointer"
          onClick={() => {
            window.open("https://everbee.io/", "_blank");
          }}
        >
          <img src={everbeeLogo} height={"100%"} alt="everbee-log" />
        </div>
        <div className="open-close-icon" >
          <img src={open ? bigcross : MobileMenuIcon} alt="logO" onClick={() =>{
            console.log("clicked me",!open)
            setOpen(prevOpen => !prevOpen);}}/>
        </div>
      </div>
      {open ? (
        <div className="nav-menu-links">
          {MenuItemDetails.map((menuItem, ind) => {
            return (
              <Link
                key={ind}
                activeClass="active"
                to={menuItem.target}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onSetActive={handleSetActive}
                onClick={() => {
                  setOpen(false);
                  handleScrollTo(menuItem.target);
                }}
              >
                {menuItem.text}
              </Link>
            );
          })}
          <button
            className="nav-join-now"
            onClick={openAffiliateLinkGoogleForm}
          >
            Join Now
          </button>
        </div>
      ) : null}
      {open ? <div className="divider"></div> : null}
    </StyledWrapper>
  );
}
