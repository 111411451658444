import React from "react";
import Button from "../Button";
import InviataionCardImg2 from "../../assets/svg/InviataionCardImg2.svg";
import mobileInvitationCard from "../../assets/svg/mobileInvitationCard.svg";
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";

import Styled from "styled-components";
import InvitationCard from "./InvitationCard";

const StyledWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  gap:2rem;
  align-items: center;
  margin: 2rem auto;
  /* border: 1px solid red; */
  .img-container{
    text-align:center;
    /* border:1px solid red;   */
  }

  .img-container img {     
      width:75%;    
      height:100%;      
      object-fit:cover;
      flex-shrink:1;
      box-shadow: 0px 4px 8px #1F76CC, 0.2;
  }
  @media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
    img{
      width:85%;
    }
  }

`;

const Invitation = ({ isMobile }) => {
  return (
    <StyledWrapper>
      {isMobile ? (
        <InvitationCard />
      ) : (
        <div className="img-container">
          <InvitationCard />
        </div>
      )}
      <div>
        <Button text="Join now" />
      </div>
    </StyledWrapper>
  );
};

export default Invitation;
