import React, { useEffect } from "react";
import StyledWrapper from "./StyledWrapper";
import everbeeLogo from "./../../assets/svg/everbee-logo.svg";
import { useLocation, useSearchParams } from "react-router-dom";
//import everbeeLogo from "@assets/svg/everbee-logo.svg";

// import { Link } from "react-router-dom";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { MenuItemDetails } from "../../assets/constants/MenuItemDetails";
import { useNavigate } from "react-router-dom";
import { affiliateLink, contactUsLink } from "../../assets/constants/links";
const NavBar = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register("begin", (to, element) => {
      // console.log('begin', to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register("end", (to, element) => {
      // console.log('end', to, element);
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const navigate = useNavigate();

  // Function to handle the activation of a link.
  const handleSetActive = (to) => {
    // console.log("checkthis");
  };

  const handleScrollTo = (targetId) => {
    if (targetId === "affiliates") {
      affiliateLink();
      setSearchParams({});
    } else if (targetId === "contactUs") {
      contactUsLink();
      setSearchParams({});
    } else if (location.pathname !== "/") {
      navigate(`/?view=${targetId}`);
    } else {
      setSearchParams({ view: targetId });
    }
  };

  useEffect(() => {
    if (searchParams.get("view")) {

      window.scroll(0, document.getElementById(searchParams.get("view")).offsetTop-50)
      // setTimeout(() => {
      //   document
      //     .getElementById(searchParams.get("view"))
      //     ?.scrollIntoView({ behavior: "smooth", block: "start"});
      // }, 100);
    }
  }, [searchParams]);

  return (
    <StyledWrapper>
      <div className="img-container">
        <div
          className="img-container cursor-pointer"
          onClick={() => {
            window.open("https://everbee.io/","_blank");
          }}
        >
          <img src={everbeeLogo} height={"100%"} alt="everbee-log" />
        </div>
      </div>
      <div className="nav-menu-links">
        {MenuItemDetails.map((menuItem, ind) => {
          return (
            <Link
              key={ind}
              activeClass="active"
              to={menuItem.target}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onSetActive={handleSetActive}
              onClick={() => handleScrollTo(menuItem.target)}
            >
              {menuItem.text}
            </Link>
          );
        })}
      </div>
    </StyledWrapper>
  );
};

export default NavBar;
