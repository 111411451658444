import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";
import React from "react";
import Styled from "styled-components";

const StyledWrapper = Styled.div`
/* border:1px solid green; */
cursor:pointer;
width: 60rem;
flex-shrink: 0;
background: radial-gradient(59.3% 50% at 50% 0%, #1F76CC 0%, #1489FC 100%);
box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.45);
display:flex;
flex-direction:column;
align-items:center;
gap:1.5rem;
padding-top:5rem;
padding-bottom:1.5rem;
margin:auto;
border-radius:10px;
.flex-column{
    display:flex;
    flex-direction:column;
}
.gap-06{
    gap:0.69
}
.ic-prize-1{
    color: #FCFCFC;
    text-align: center;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4rem; /* 160% */
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
    opacity: 0.4;
}
.flex-column p{
    width: 47.8125rem;
    color: var(--White, #FFF);
    text-align: center;
    font-family: Satoshi;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.6rem; /* 104% */
    text-transform: uppercase;
}

.ic-image-container{    
    flex-shrink: 0;       
    /* border:1px solid red; */
    border-radius:15px;
    img{
        width:100%;
    }
}

@media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}){

/* border:1px solid green; */
width: 21.875rem;
height: 37.0625rem;
flex-shrink: 0;
background: radial-gradient(59.3% 50% at 50% 0%, #1F76CC 0%, #1489FC 100%);
box-shadow: 0px 0px 100px 10px rgba(0, 0, 0, 0.45);
display:flex;
flex-direction:column;
align-items:center;
gap:1.5rem;
padding-top:5rem;
padding-bottom:1.5rem;
margin:auto;
border-radius:10px;
.flex-column{
    display:flex;
    flex-direction:column;
}
.gap-06{
    gap:0.69
}
.ic-prize-1{
    color: #FCFCFC;
    text-align: center;
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4rem; /* 160% */
    letter-spacing: 0.03125rem;
    text-transform: uppercase;
    opacity: 0.4;
}
.flex-column p{
    width:17.5rem;
    color: var(--White, #FFF);
    text-align: center;
    font-family: Satoshi;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.ic-image-container{    
    flex-shrink: 0;       
    /* border:1px solid red; */
    overflow:hidden;
    border-radius:15px;
    img{
        width:100%;
    }
}



}

`;

export default StyledWrapper;
