import React from 'react';
import Styled from 'styled-components';

const StyledWrapper = Styled.div`
      /* border:1px solid red;          */
      display:flex;
      flex-direction:column;    
      justify-content:flex-start;
      gap:2rem;     
      margin:auto;       
      padding: 1rem 1.25rem;    
      border-bottom: 1px solid var(--Blue-300, #A5D2FE); 
      background: var(--White, #FFF);          
      position:fixed;
      top:0;
      width:100%; 
      z-index:10;
      height:auto;       
      ${({ open }) => open && `
        box-sizing: border-box;    
        padding: 1rem 1.25rem;        
        height:100vh; 
        border-bottom: 0px solid var(--Blue-300, #A5D2FE); 
        .divider{
          border-top: 1px solid var(--Blue-300, #A5D2FE);
          flex:1;   
          margin-left:-1.25rem;
          margin-right:-1.25rem;  
         }   
      `}

    .nav-row-1{
      /* border:1px solid green; */
      display:flex;
      justify-content:space-between;   
      align-items:center;       
    }   
    .nav-menu-links{
      display: flex;
      align-items: flex-start;
      flex-direction:column;
      gap: 3rem;
      a{
        color: var(--Grey-900, #19191A);
        font-family: Satoshi;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; 
        text-decoration:none;
        cursor:pointer;
      }
    }
    .nav-join-now{
      color: var(--White, #FFF);
      cursor:pointer;
      font-family: Satoshi;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.1875rem;
      display: flex;
      padding: 0.5rem 0.75rem 0.5625rem 0.75rem;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      align-self: stretch;
      border-radius: 0.375rem;
      background: var(--Blue-700, #3D98F2);
      border:none;
      width:100%;
      box-shadow: 0px 0px 0px 1px #3490EC, 0px 1px 1px 0px rgba(13, 97, 181, 0.80), 0px 1px 0px 0px rgba(255, 255, 255, 0.30) inset;
    } 
    .open-close-icon{
      cursor:pointer;
    } 
    .cursor-pointer{
      cursor:pointer;
    }  

`;

export default StyledWrapper;
