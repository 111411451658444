import Styled from 'styled-components';
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = Styled.div`
    display: flex;
    flex-direction:column;
    padding:2rem 0rem;
    width:fit-content;
    margin:auto;
    justify-content: center;
    align-items: center;
    font-family: Satoshi;
.title{
    display:flex;
    /* border:1px solid green; */
    color: var(--Grey-900, #19191A);
    flex-direction:column;
    text-align: center;   
    font-style: normal;     
    span{
        font-size:5rem;
        font-weight: 900;
        line-height:4.1rem;
        letter-spacing: -0.15rem; 
    }
}
.bluecolor{
    /* color:#1C69B5; */
    background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.title-logo{
    /* border:1px dashed green; */
    display: flex;
    flex-direction: column;
    align-items: center;   
}
.tag-button{
    /* border:1px solid yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    p{
        width: 42.9375rem;
        color: var(--Grey-800, #313233);
        text-align: center;
        font-family: Poppins;
        font-size: 1.60rem;
        font-style: normal;
        font-weight: 600;      
        line-height: 139%;
    }
}
     img{
    width:45%;   
    flex-shrink: 0;
}

@media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
/* border:1px solid red; */
display: flex;
flex-direction:column;
padding-top:7rem;
width:85%;
margin:auto;
justify-content: center;
align-items: center;
font-family: Satoshi;
align-self: stretch;
.title{
    display:flex;
    /* border:1px solid green; */
    color: var(--Grey-900, #19191A);
    flex-direction:column;
    text-align: center;   
    font-style: normal;     
    span{
        font-size: 3.75rem;
        font-style: normal;
        font-weight: 900;
        line-height: 3.125rem; /* 83.333% */
        letter-spacing: -0.075rem;
    }
}
.bluecolor{
    /* color:#1C69B5; */
    background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.title-logo{
    /* border:1px dashed green; */
    margin-top:3rem;
    display: flex;
    flex-direction: column;
    align-items: center;    
}
.tag-button{
    /* border:1px solid yellow; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    max-width:100%; 
    /* border:2px dashed yellow; */
    p{ 
        width: 100%;
        /* border:2px dashed blue; */
        color: var(--Grey-800, #313233);
        text-align: center;
        font-family: Satoshi;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;      
        line-height: 139%;        
    }
}
img{
    width:45%;   
    flex-shrink: 0;
}    
}

`;

export default StyledWrapper;
