import Styled from 'styled-components';
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = Styled.div`
/* border:1px solid blue; */
text-align:center;
width:75%;
margin:auto;
display: flex;
flex-direction: column;
align-items: center;
gap:0.5rem;
.hiw-title{
    /* border:1px solid red; */
    font-family: Satoshi;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 900;    
    /* line-height: 7.5rem; */
    letter-spacing: -0.1825rem;    
    margin-top:0px;
    margin-bottom:0px;
    background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding:0rem 0.5rem;
}
.join-button{
    margin-top:1.5rem;
}
.hiw-process-steps{
    /* border:1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.00rem;
    align-self: stretch;
}
.hiw-process-step{
    /* border:1px solid green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    flex: 1 0 0;
    h1{
        /* border:1px solid green; */
        width:95%;
        color: var(--Grey-800, #313233);
        text-align: center;
        font-family: Poppins;
        font-size: 1.275rem;
        font-style: normal;
        font-weight: 600;
        line-height: 139%;
    }
    p{
         /* border:1px solid green; */
        color: var(--grey-600, #646566);
        text-align: center;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 139%;
    }    
}
.hiw-process-step>p>span{
    font-weight:600;
}

@media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
    width:85%;
    text-align:center;    
    margin:4rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.5rem;
    padding-top:1rem;
    /* border:1px solid red; */
    .hiw-title{
        /* border:1px solid red; */
        font-family: Satoshi;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 900;    
        line-height: 2.25rem;
        letter-spacing: -0.1825rem;    
        margin-top:0px;
        margin-bottom:0px;
        background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding:0rem 0.5rem;
    }
    .join-button{
        margin-top:1.5rem;
    }
    .hiw-process-steps{
        /* border:1px solid red; */
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        align-self: stretch;
    }
    .hiw-process-step{
        /* border:1px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0rem;
        flex: 1 0 0;
        h1{
            /* border:1px solid green; */
            width: 95%;
            color: var(--Grey-800, #313233);
            text-align: center;
            font-family: Poppins;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 600;
            line-height: 139%;
        }
        p{
            /* border:1px solid green; */
            width:100%;
            color: var(--grey-600, #646566);
            text-align: center;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 139%;
        }    
    }
    .hiw-process-step>p>span{
        font-weight:600;
    }    
}

`;

export default StyledWrapper;
