import React from "react";
import TitleChallange from "../components/TitleChallenge";
import HowItWorks from "../components/HowItWorks";
import AwardsAndPrizes from "../components/AwardsAndPrizes";
import InvitationCard from "../components/InvitationCard/Invitation";
import LeaderBoard from "../components/LeaderBoard";
import Faqs from "../components/Faqs";
import Footer from "../components/Footer";
import PodCastSection from "../components/PodCastSection";

const HomePage = ({ isMobile }) => {
  return (
    <>
      <TitleChallange />
      <HowItWorks />
      <AwardsAndPrizes isMobile={isMobile} />
      <InvitationCard isMobile={isMobile} />
      <LeaderBoard />
      <Faqs isMobile={isMobile} />
      <PodCastSection isMobile={isMobile} />
      <Footer isMobile={isMobile} />
    </>
  );
};

export default HomePage;