import React from "react";
import StyledWrapper from "./StylesWrapper";
import price1 from "../../assets/svg/1st-place.svg";
import price2 from "../../assets/svg/2nd-place.svg";
import price3 from "../../assets/svg/3rd-place.svg";
import price4 from "../../assets/svg/4th-6th-place.svg";
import price5 from "../../assets/svg/7th-10th-place.svg";
import mobileawards from "../../assets/svg/mobile-awards.svg";

const AwardsAndPrizes = ({ isMobile }) => {
  return (
    <StyledWrapper id="awardsAndPrizes">
      <div className="app-title-tag">
        <p className="aap-title">AWARDS & prizes</p>
        <p className="app-tag">
          The first prize winner gets an invitation to join the Etsy Seller
          Podcast with EverBee Founder & CEO
        </p>
      </div>
      {!isMobile ? (
        <div className="prizes">
          <div className="app-row1-prizes">
            <div className="app-prize-container">
              <img src={price1} alt="row1-prize" />
            </div>
            <div className="app-prize-container">
              <img src={price2} alt="row1-prize" />
            </div>
            <div className="app-prize-container">
              <img src={price3} alt="row1-prize" />
            </div>
          </div>
          <div className="app-row2-prizes W-66">
            <div className="app-prize-container">
              <img src={price4} alt="row1-prize" />
            </div>
            <div className="app-prize-container">
              <img src={price5} alt="row1-prize" />
            </div>
          </div>
        </div>
      ) : (
        <div className="prizes">
          <div className="app-row1-prizes">
            <div className="app-prize-container">
              <img src={price1} alt="row1-prize" />
            </div>
            <div className="app-prize-container">
              <img src={price2} alt="row1-prize" />
            </div>
          </div>
          <div className="app-row2-prizes">
            <div className="app-prize-container">
              <img src={price3} alt="row1-prize" />
            </div>
            <div className="app-prize-container">
              <img src={price4} alt="row1-prize" />
            </div>            
          </div>
          <div className="app-row3-prizes">
            <div className="app-prize-container">
              <img src={price5} alt="row1-prize" />
            </div>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
};

export default AwardsAndPrizes;
