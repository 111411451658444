import React from "react";
import Styled, {css} from "styled-components";
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = Styled.div`
/* border:1px solid red; */
display: flex;
width:70%;
margin:auto;
flex-direction: column;
align-items: center;
gap: 0.5rem;
align-self: stretch;
.lb-heading{
    text-align: center;
    font-family: Satoshi;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: 120%; /* 5.475rem */
    letter-spacing: -0.1825rem;
    text-transform: uppercase;
    background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin:0.5rem auto;
    padding:0rem 0.5rem;
}
    .leaderboard-container {
        /* border:1px dashed red; */
        display: flex;
        flex-direction:column;       
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;       
    }
    .header-row{
        display: flex;
        padding: 1rem;
        align-items: center;
        gap: 1rem;
        align-self: stretch;
        background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
        color: var(--White, #FFF);
        font-family: Satoshi;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        /* line-height: 2.5rem; */
        border-top-left-radius: 10px; /* Border-radius for the top-left corner */
        border-top-right-radius: 10px;
    }    

    .affiliate-email {
        width: 40%;       
        /* border:1px solid red;  */
    }

    .conversions {
        width: 25%;        
    }

    .place {
        width:25%;       
        /* width: 26.125rem; */
    } 
    .status{
        width:10%;
        text-align:right;
    }
    .display-none{
        display:none;
    }
    .data-row{
        /* border:1px solid blue;      */
        display: flex;
        padding: 0rem 1rem;
        align-items: center;
        gap: 2.5rem;
        align-self: stretch;
        background: var(--grey-100, #FAFAFA);
        color: var(--Grey-900, #19191A);
        font-family: Satoshi;
        font-size: 1.35rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;      
    }
    .results{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.4rem;
        align-self: stretch;
        border-radius: 1.25rem;
    }    
@media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
    display: flex;
    width:100%;
    /* border:1px solid red; */
    margin:auto;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;   
    .lb-heading{
        text-align: center;
        font-family: Satoshi;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 900;       
        letter-spacing: -0.1825rem;
        line-height: 2.25rem;
        text-transform: uppercase;
        background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin:0.5rem auto;
        /* padding:0rem 0.1rem; */
        /* border:1px solid red; */
    }
        .leaderboard-container {
            /* border:1px dashed red; */
            width:95%;
            margin:auto;
            display: flex;
            flex-direction:column;       
            align-items: center;
            gap: 0.5rem;
            align-self: stretch;       
        }
        .header-row{
            display: flex;
            padding: 1rem;
            align-items: center;
            gap: 1rem;
            align-self: stretch;
            background: var(--Linear-text, linear-gradient(90deg, #1F76CC 0.11%, #1C69B5 44.9%, #4CA4FA 82.4%, #1D8FFF 100.11%));
            color: var(--White, #FFF);
            font-family: Satoshi;
            font-size: 0.87rem;
            font-style: normal;
            font-weight: 700;
            /* line-height: 2.5rem; */
            border-top-left-radius: 10px; /* Border-radius for the top-left corner */
            border-top-right-radius: 10px;
        }    

        .affiliate-email {
            width: 40%;       
            /* border:1px solid red;  */
        }

        .conversions {
            width: 25%;        
        }

        .place {
            width:25%;       
            /* width: 26.125rem; */
        } 
        .status{
            width:10%;
            text-align:right;
        }
        .display-none{
            display:none;
        }
        .data-row{
            /* border:1px solid blue;      */
            display: flex;
            padding: 0rem 1rem;
            align-items: center;
            gap: 1rem;
            align-self: stretch;
            background: var(--grey-100, #FAFAFA);
            color: var(--Grey-900, #19191A);
            font-family: Satoshi;
            font-size: 0.87rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;      
        }
        .results{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.4rem;
            align-self: stretch;
            border-radius: 1.25rem;
        }
        .status img{
        width:1.5rem;
        height:1.5rem;
         }
    }
    ${({ blurLeaderBoard }) =>
      blurLeaderBoard &&
      css`
        .data-row > .affiliate-email,
        .data-row > .conversions {
          filter: blur(5px);
        }
      `}
       
    

   
  

`;

export default StyledWrapper;
