import React, { useEffect } from "react";
import StyledWrapper from "./StyledWrapper";

const TermsAndConditions = () => {
  const termsAndConditions = [
    {
      heading: "Eligibility",
      content:
        "Participation in the Partner Challenge is open exclusively to registered affiliates of EverBee.",
    },
    {
      heading: "Challenge Period",
      content:
        "The Challenge will take place from February 1 to February 29, 2024. EverBee reserves the right to modify the Challenge period at its sole discretion. Any such extension will be communicated to participants through official channels.",
    },
    {
      heading: "Challenge Objectives and Prizes",
      content:
        "Prizes will be awarded based on the number of new conversions made only via the provided by the EverBee Landing page. These conversions must have an affiliate tag for tracking purposes. Participants are required to promote EverBee on YouTube, Facebook, TikTok, Instagram, personal blogs, or any other Social Media platform, excluding PPC (Pay-Per-Click) or any other paid traffic.",
    },
    {
      heading: "Prize Distribution",
      content:
        "Monetary prizes will be transferred to the PayPal account specified by the participant within one week after the Challenge concludes.",
    },
    {
      heading: "Disqualification",
      content:
        "EverBee reserves the right to disqualify any participant if there is suspicion of a violation of the rules outlined in these Terms & Conditions. Disqualification may result in the forfeiture of any prizes.",
    },
    {
      heading: "Social Media Platforms",
      content:
        "Participants are allowed to promote EverBee on YouTube, Facebook, TikTok, Instagram, personal blogs, or any other Social Media platform, except for PPC or any other paid type of traffic.",
    },
    {
      heading: "Data and Privacy",
      content:
        "By participating in the Challenge, you consent to the collection and use of your personal information by EverBee to administer the Challenge and award prizes.",
    },
    {
      heading: "Changes and Termination",
      content:
        "EverBee reserves the right to make changes to the Challenge, including but not limited to rules, objectives, and prizes, and to terminate the Challenge at any time, with or without notice.",
    },
    {
      heading: "Contact Information",
      content:
        "For any inquiries related to the Challenge or these Terms & Conditions, please contact EverBee at <a href='https://help.everbee.io' target='_blank'>help.everbee.io</a>",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <StyledWrapper>
      <p className="tc-heading">Partner Challenge - Terms & Conditions</p>
      <p className="effective-date">Effective January 25, 2024</p>
      {/* 1 terms and conditions */}
      {termsAndConditions.map(({ heading, content }, index) => (
        <div key={`${heading}`}>
          <p className="tc-sub-heading">
            {index + 1}. {heading}
          </p>
          <p
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      ))}
      <p className="content">
        By participating in the EverBee.io Partner Challenge, you acknowledge
        that you have read, understood, and agreed to these Terms & Conditions.
      </p>
    </StyledWrapper>
  );
};

export default TermsAndConditions;
