import React from 'react';
import FaqCard from './FaqCard';
import StyledWrapper from './StyledWrapper';
import MobileFaqCard from './MobileFaqCard';

const Faqs = ({isMobile}) => {
  const faqsData = [
    {
      question: 'Who is eligible to participate in the Partner Challenge?',
      ans: 'Only registered affiliates of EverBee.io are eligible to participate in the Challenge.',
    },
    {
      question: 'When will the Challenge take place?',
      ans: 'The Challenge will run from February 1 to February 29, 2024.',
    },
    {
      question: 'How are Challenge prizes determined?',
      ans: 'Prizes are based on the number of new conversions made exclusively via the special landing page that will be provided.',
    },
    {
      question: 'How can I promote EverBee?',
      ans: 'Participants are encouraged to promote EverBee on YouTube, Facebook, TikTok, Instagram, personal blogs, or any other Social Media platform. However, paid traffic is not allowed.',
    },
    {
      question: 'If I have any questions, who do I contact?',
      ans: "Anytime you have any questions or feedback, please reach out to: <a href='https://help.everbee.io' target='_blank'>help.everbee.io</a> we’d love to help you in any way we can.",
    },
  ];
  return (
    <StyledWrapper id="faq">
    <p className="faq-heading">FAQ</p>
    <div className="faq-questions-container">
      {faqsData.map(({ question, ans }, ind) => (
        isMobile ? (
          <MobileFaqCard
            key={`${ind}-faq`}
            question={question}
            ans={ans}
            totalQuestions={faqsData.length}
            ind={ind}
          />
        ) : (
          <FaqCard
            key={`${ind}-faq`}
            question={question}
            ans={ans}
            totalQuestions={faqsData.length}
            ind={ind}
          />
        )
      ))}
    </div>
  </StyledWrapper>
  );
};

export default Faqs;
