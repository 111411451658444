import styled from 'styled-components';
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = styled.div`
  /* border: 1px dashed green; */
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  align-self: stretch;  
  margin: 1rem auto;
  padding-top: 1rem;
  .app-title-tag {
    /* border:1px solid green; */
  }
  .aap-title {
    text-align: center;
    font-family: Satoshi;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: 120%; 
    letter-spacing: -0.1825rem;
    text-transform: uppercase;
    background: var(
      --Linear-text,
      linear-gradient(
        90deg,
        #1f76cc 0.11%,
        #1c69b5 44.9%,
        #4ca4fa 82.4%,
        #1d8fff 100.11%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding: 0rem 0.5rem;
  }
  .app-tag {
    /* border:1px solid red; */
    width: 100%;
    color: var(--Grey-900, #19191a);
    text-align: center;
    font-family: Satoshi;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0rem auto; //check this
  }
  .app-row1-prizes,
  .app-row2-prizes {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5rem;
    /* border:1px dashed red;    */
    margin: auto;
  }


  .app-prize-container {
    /* border:1px solid red;  */
    flex:1;
    text-align: center;
    filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.75));
  }
  .prizes {
    padding-top: 1rem;
    /* border:1px solid red; */
  }
  img {      
      width:75%;     
      height:auto;
      object-fit:contain;        
    }   
   .W-66{
    width: 66.66%
   }

  @media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
    /* border:1px solid red;   */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    width: 85%;
    margin: 1rem auto;
    padding-top: 1rem;
    .app-title-tag {
      /* border:1px solid green; */
    }
  .aap-title {
    text-align: center;
    font-family: Satoshi;
    font-size: 2.5rem;
    padding:1rem 0rem 1rem 0;
    font-style: normal;
    font-weight: 900;
    line-height: 2.25rem;
    letter-spacing: -0.1rem;
    text-transform: uppercase;
    background: var(
      --Linear-text,
      linear-gradient(
        90deg,
        #1f76cc 0.11%,
        #1c69b5 44.9%,
        #4ca4fa 82.4%,
        #1d8fff 100.11%
      )
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 0rem;
    margin-bottom: 0rem;   
  }
  .app-tag {    
    width:100%;
    color: var(--Grey-900, #19191a);
    text-align: center;
    font-family: Satoshi;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    margin: 0rem auto; //check this
  }
  .app-row1-prizes,
  .app-row2-prizes,
  .app-row3-prizes {
    /* border:5px dashed orange;   */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1.875rem;
    }
    .app-prize-container {
      /* border:1px dashed blue; */
      flex:1;
      text-align: center;
      filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.75));
    }
    .prizes {
      padding-top: 1rem;  
      display:flex;
      flex-direction:column;
      gap:2.5rem;    
    }
    img {      
      width:100%;     
      height:auto;
      object-fit:contain;
        
    }    
  }

  .app-row3-prizes .app-prize-container{
   flex:0.5;
  }
  

`;

export default StyledWrapper;
