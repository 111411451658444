import React from 'react';
import Styled from 'styled-components';
import { MOBILE_TAB_SCREEN_WIDTH } from "../../assets/constants/ScreenSIze";


const StyledWrapper = Styled.div`
  box-sizing:border-box;
  border-radius:9px;
  width:90%;  
  margin:auto;
  padding:2.5rem;
  display:flex;
  flex-direction:column;
  gap:1rem;
  background:#fff;
  margin-bottom:1rem;
   .tc-heading{
    color: var(--Grey-900, #19191A);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Plus Jakarta Sans";
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top:0px;
    margin-bottom:0px;
  }
  .tc-sub-heading{
    color: var(--Grey-900, #19191A);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Plus Jakarta Sans";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .content{
    align-self: stretch;
    color: var(--Grey-900, #19191A);
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .effective-date{
    align-self: stretch;
    color: var(--Grey-900, #19191A);
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }

  @media only screen and (max-width: ${MOBILE_TAB_SCREEN_WIDTH}) {
    .tc-heading{
      margin-top:2.5rem;
      margin-bottom:0px;
      font-size: 2rem;
    }
    .tc-sub-heading{
    color: var(--Grey-900, #19191A);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Plus Jakarta Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .content{
    align-self: stretch;
    color: var(--Grey-900, #19191A);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  }

`;

export default StyledWrapper;

