import React from 'react';
import StyledWrapper from './StyledWrapper';

const Slider = () => {
  return (    
    <StyledWrapper>
      <div className="scroll .LeftToRight">
        <div>
          <span className="pcs-row-1">EverBee</span> Partner Challenge February
        </div>
        <div>
          EverBee <span className="pcs-row-2">Partner</span> Challenge February
        </div>
        <div>
          EverBee Partner <span className="pcs-row-3">Challenge</span> February
        </div>
        <div>
          EverBee Partner Challenge <span className="pcs-row-4">February</span>
        </div>
      </div>
    </StyledWrapper>  
  );
};

export default Slider;
