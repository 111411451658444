import React from 'react';

const ProcessStep = ({ stepNumber, heading, tag }) => {
  return (
    <div className="hiw-process-step">
      <h1>{`${stepNumber}. ${heading}`}</h1>
      <p dangerouslySetInnerHTML={{ __html: tag }} />
    </div>
  );
};

export default ProcessStep;
