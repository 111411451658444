import React from 'react';
import StyledWrapper from './StyledWrapper';
import { openTermsAndConditionsLink } from '../../assets/constants/links';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate=useNavigate();
  return (
    <StyledWrapper>
      <p className="copy-right">Copyright EverBee. All rights reserved 2024</p>
      <p className="tc" onClick={()=>navigate("/terms-and-conditions")}>
        Terms & Conditions
      </p>
    </StyledWrapper>
  );
};

export default Footer;
